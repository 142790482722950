<template>
  <div class="create-exam">
    <div class="create-exam-header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/examManage/create' }"
          >考试管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>创建考试</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="exam-hr" style="margin-top: 26px"></div>
    <!-- 主体 -->
    <div class="exam-main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="exam-title">
          <div class="green-block"></div>
          <span>考场设置</span>
        </div>
        <!-- 考场设置 -->
        <div class="exam-setting">
          <el-form-item
            label="考试名称"
            prop="examName"
            style="margin-left: 1px; width: 446px"
          >
            <el-input
              v-model="ruleForm.examName"
              placeholder="考试名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择考场" prop="examRoom">
            <el-select
              v-model="ruleForm.examRoom"
              placeholder="请选择考场"
              style="width: 347px"
            >
              <el-option
                v-for="item in examRoomList"
                :key="item.id"
                :label="item.examination_room_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 考试时间 -->
          <div class="exam-time">
            <el-form-item
              label="开始时间"
              style="margin-left: 1px; width: 446px"
              prop="startTime"
            >
              <el-date-picker
                v-model="ruleForm.startTime"
                type="datetime"
                placeholder="选择开始时间"
                style="width: 346px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="结束时间"
              style="margin-left: 1px; width: 446px"
              prop="endTime"
            >
              <el-date-picker
                v-model="ruleForm.endTime"
                type="datetime"
                placeholder="选择结束时间"
                style="width: 346px"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
        <!-- 选择考生 -->
        <div class="exam-title">
          <div class="green-block"></div>
          <span>选择考生</span>
          <div class="change-btn">
            <el-button type="primary" @click="changeStudent"
              >选择学生</el-button
            >
          </div>
        </div>
        <!-- 选择考生表格 -->
        <div class="change-student">
          <el-table
            class="customTable"
            :data="studentList"
            style="margin-top: 20px; flex: 1"
            size="medium"
            max-height="480px"
            :header-cell-style="{
              fontWeight: 'normal',
              height: '60px',
              color: '#666666',
              background: '#F6F6F6',
              fontSize: '16px',
            }"
            :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }"
          >
            <el-table-column prop="sid" label="序号" align="center">
            </el-table-column>
            <el-table-column prop="name" label="学生姓名" align="center">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-link
                  type="danger"
                  :underline="false"
                  @click="deleteStudentItem(scope.row)"
                  >删除</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 选择考生会话框 -->
        <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="studentTitle"
          custom-class="green-dialog"
          :visible.sync="studentDialogVisible"
          width="500px"
          @opened="selectStudentList"
        >
          <span style="margin-right: 15px">学生分类</span>
          <el-select
            v-model="studentSelect"
            placeholder="请选择考试场次"
            @change="selectStudentList"
          >
            <el-option
              v-for="item in studentCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button style="margin-left:10px" @click='allStudentClick' type='primary'>选择全部考生</el-button>
          <div class="student-table">
            <el-checkbox-group
              v-model="studentChecked"
              v-for="(item, index) in studentTable"
              :key="index"
            >
              <el-checkbox :label="item.user_id" class="student-check">{{
                item.nickname
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="dialog-footer" style="margin-top: 20px">
            <el-button @click="studentDialogVisible = false">取 消</el-button>
            <el-button
              style="margin-left: 30px"
              type="primary"
              @click="studentConfirmForm"
              >确 定</el-button
            >
          </div>
        </el-dialog>

        <!-- 选择试卷 -->
        <div class="exam-title" style="margin-top: 44px">
          <div class="green-block"></div>
          <span>选择试卷</span>
          <div class="change-btn">
            <el-button type="primary" @click="changePapers">选择试卷</el-button>
          </div>
        </div>
        <!-- 选择试卷表格 -->
        <div class="change-paper">
          <el-table
            class="customTable"
            :data="paperList"
            style="margin-top: 20px; flex: 1"
            size="medium"
            :header-cell-style="{
              fontWeight: 'normal',
              height: '60px',
              color: '#666666',
              background: '#F6F6F6',
              fontSize: '16px',
            }"
            :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }"
          >
            <el-table-column prop="pid" label="序号" width="80" align="center">
            </el-table-column>
            <el-table-column
              prop="category"
              label="考试类型"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="试卷名称"
           
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="levelname"
              label="试卷分类"
             
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <el-button @click="changePapers" type="text" size="small"
                  >更换</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  style="color: red"
                  @click="delPaperData(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="paperTitle"
          custom-class="green-dialog"
          :visible.sync="paperDialogVisible"
          width="50%"
          @opened="editPaperList"
        >
          <!-- 考试形式 -->
          <div class="paper-item">
            <span style="margin-right: 15px; line-height: 40px"
              >考试类型选择</span
            >
            <el-select
              v-model="paperCategory"
              placeholder="请选择"
              @change="clickPaperCategory"
              style="width: 190px"
            >
              <el-option
                v-for="item in paperOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 试卷类型 -->
          <div class="paper-category" style="margin: 25px 0 20px 0">
            <span style="margin-right: 15px; line-height: 40px;width: 80px;display: inline-block;">试卷分类</span>
            <el-select
              v-model="paperValue"
              placeholder="请选择"
              @change="clickPaperValue"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.level_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- 搜索 -->
            <el-input
              v-model="search_paper"
              placeholder="试卷名搜索"
              style="width: 320px; margin-left: 220px"
            ></el-input>
            <el-button
              style="margin-left: 10px"
              type="primary"
              @click="searchPaper"
              >搜索</el-button
            >
          </div>
          <div
            style="margin-top:26px;font-size:15px;font-weight:bold;color:red"
          >
            *只能选择表格中的一组试卷！
          </div>
          <el-table
            ref="multipleTable"
            :data="paperTableData"
            tooltip-effect="dark"
            style="width: 100%; margin-top: 5px; flex: 1"
            max-height="350"
            class="customTable"
            @selection-change="handleSelectionChange"
            :header-cell-style="{
              fontWeight: 'normal',
              height: '60px',
              color: '#666666',
              background: '#F6F6F6',
              fontSize: '16px',
            }"
            :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }"
          >
            <el-table-column type="selection" width="85"> </el-table-column>
            <el-table-column
              prop="name"
              label="试卷名称"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="level_name"
              label="试卷分类"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table>

          <div class="dialog-footer" style="margin-top: 20px">
            <el-button @click="closePaper">取 消</el-button>
            <el-button
              style="margin-left: 30px"
              type="primary"
              @click="paperconfirmForm"
              >确定</el-button
            >
          </div>
        </el-dialog>
        <!-- 选择评委 -->
        <div
          class="exam-title"
          style="margin-top: 44px"
          v-if="paperCategory != 0"
        >
          <div class="green-block"></div>
          <span>选择评委</span>
          <div class="change-btn">
            <el-button type="primary" @click="changeJudge">选择评委</el-button>
          </div>
        </div>
        <!-- 选择评委表格 -->
        <div class="change-judge" v-if="paperCategory != 0">
          <el-table
            class="customTable"
            :data="judgeList"
            style="margin-top: 20px; flex: 1"
            size="medium"
            :header-cell-style="{
              fontWeight: 'normal',
              height: '60px',
              color: '#666666',
              background: '#F6F6F6',
              fontSize: '16px',
            }"
            :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }"
          >
            <el-table-column prop="jid" label="序号" align="center">
            </el-table-column>
            <el-table-column prop="name" label="评委名称" align="center">
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-link
                  type="danger"
                  :underline="false"
                  @click="deleteJudgeItem(scope.row)"
                  >删除</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="judgeTitle"
          custom-class="green-dialog"
          :visible.sync="judgeDialogVisible"
          width="500px"
        >
          <div class="student-table">
            <el-checkbox-group
              v-model="judgeChecked"
              v-for="(item, index) in judgeTable"
              :key="index"
            >
              <el-checkbox :label="item.id" class="student-check">{{
                item.nickname
              }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="dialog-footer" style="margin-top: 20px">
            <el-button @click="judgeDialogVisible = false">取 消</el-button>
            <el-button
              style="margin-left: 30px"
              type="primary"
              @click="judgeConfirmForm"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </el-form>
      <div class="dialog-footer" style="margin-top: 20px">
        <el-button @click="cancelExam">取 消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="confirmForm('ruleForm')"
          >保 存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  showExaminatinRoom,
  examStudentList,
  showExamStudentList,
  showExamJudgeList,
  showPaperList,
  createExamData,
  editExamData,
  showPaperLevel,
} from "@/utils/apis";
export default {
  data() {
    var checkStartTime = (rule, value, callback) => {
       console.log('value',value);
       return callback();

    }
    return {
      Pages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      examRoomList: [], //选择考场数据
      allStudentList: [],
      studentTitle: "",
      studentDialogVisible: false,
      studentCategoryList: [], //学生分类列表
      studentSelect: "全部", //选择学生下拉框数据
      studentChecked: [], //学生多选框数据
      studentTable: [], //学生多选框列表数据
      paperTitle: "",
      paperDialogVisible: false,
      paperValue: "全部", //试卷类型
      paperCategory: "", //考试形式
      paperTableData: [], //考试试卷列表
      options: [], //试卷类型下拉框数据
      paperOptions: [
        {
          id: 0,
          value: 0,
          label: "理论考试",
        },
        {
          id: 1,
          value: 1,
          label: "实操考试",
        },
        /*{
          id: 2,
          value: 2,
          label: "综合考试",
        }*/
      ], //考试形式下拉框数据
      changePaper: [], //考试试卷下拉框数据
      search_paper: "", //试卷搜索
      judgeTitle: "",
      judgeDialogVisible: false,
      judgeChecked: [], //选择评委多选框数据
      judgeTable: [], //评委多选框列表数据
      rules: {
        examRoom: [
          { required: true, message: "请选择考场", trigger: "change" },
        ],
        examName: [
          { required: true, message: "请输入考场名称", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: '请输入考试开始时间', trigger: ['change', 'blur'] },
        ],
        endTime: [
          { required: true, message: '请输入考试结束时间', trigger: ['change', 'blur']}
        ]

      },
      studentList: [], //学生列表
      paperList: [], //试卷列表
      judgeList: [], //评委列表
      ruleForm: {
        id: "", //更新
        examName: "", //考试名称
        startTime: "", //开始时间
        endTime: "", //结束时间
        examRoom: "", //考场
        studentData: "", //选择考生
        paperData: "", //选择试卷
        judgeData: "", //选择评委
      },
      editPaperId: "", //获取编辑时的试卷id
      paperAllvalue: "", //试卷分类为全部则获取考试列表中的考试级别
      dialogFlag: 0, //用于控制考试会话框回调
      paperListTotal: [],
    };
  },

  mounted() {
    this.ruleForm.id = this.$route.query.id;
    this.showExamRoom();
    this.showStudentList();
    this.showJudgeListData();
    this.showpaperLevelData();
    this.showPaperListData();
  },
  methods: {
    // 选择全部考生
    allStudentClick(){
      let arr=[]
      this.studentTable.forEach((item)=>{
        arr.push(item.user_id)
        this.studentChecked=arr
      })
    },
    // 显示考试分类
    showpaperLevelData() {
      showPaperLevel().then((res) => {
        if (res.code === 200) {
          this.options = res.data;
          let obj = {
            id: "全部",
            level_name: "全部",
          };
          this.options.unshift(obj);
        }
      });
    },
    // 选择考试类型显示列表
    clickPaperCategory() {
      let params = { exam_type: this.paperCategory };
      showPaperList(params).then((res) => {
        if (res.code === 200) {
          this.paperTableData = res.data;
        }
      });
    },
    // 选择试卷分类显示列表
    clickPaperValue() {
      let params = {
        exam_type:this.paperCategory
      };
      if (this.paperValue != "全部") {
        params.grade_id = this.paperValue;
      }
      showPaperList(params).then((res) => {
        if (res.code === 200) {
          this.paperTableData = res.data;
        }
      });
    },
    // 根据输入框搜索试卷
    searchPaper() {
      let params = {
        paper_name: this.search_paper,
      };
      showPaperList(params).then((res) => {
        if (res.code === 200) {
          if (res.data == "") {
            this.$message({
              type: "success",
              message: "搜索的试卷不存在",
            });
          } else {
            this.paperTableData = res.data;
          }
        }
      });
    },
    //点击考试会话框取消按钮，取消会话框中已经选择的考试试卷
    cancelList(){
      this.$nextTick(() => {
          this.paperTableData.forEach((val) => {
            this.paperList.forEach((item) => {
              if (item.id != val.id) {
                this.$refs.multipleTable.toggleRowSelection(val, false);
              }else{
                this.$refs.multipleTable.toggleRowSelection(val, true);
              }
            });
          });
        });
    },
    // 点击考试会话框的取消按钮
    closePaper() {
      if (this.$route.query.id) {
        this.paperDialogVisible = false;
        this.search_paper = "";
        // 如果没有选择试卷分类就默认获取全部列表中的某一张试卷的考试等级
        if (this.paperValue == "全部") {
          this.paperAllvalue = this.paperListTotal
            .map((item) => item.grade)
            .join("");
        }
        // 如果表格多选了，点击取消后，将去除之前多选的，保留已经添加paperlist中的
        this.cancelList()
      } else {
        this.paperDialogVisible = false;
        // this.paperCategory = "";
        this.search_paper = "";
        this.paperValue = "全部";
        if (this.paperListTotal.length == 1) {
          return false;
        } else {
          this.cancelList()
        }
      }
    },
    // 勾选考试列表添加到考试表格中
    handleSelectionChange(row) {
      // 如果用户没有选择考试类型，直接勾选考试试卷后，根据试卷自动匹配试卷类型
      this.paperOptions.forEach((item) => {
        row.forEach((subItem) => {
          if (item.id == subItem.exam_type) {
            this.paperCategory = item.id;
          }
        });
      });
      let pindex = 1;
      let arr = row.reduce((acc, cur) => {
        acc.push({
          id: cur.id,
          name: cur.name,
          levelname: cur.level_name,
          pid: pindex++,
          grade: cur.grade_id,
          category:
            this.paperCategory == 0
              ? "理论考试"
              : this.paperCategory == 1
              ? "实操考试"
              : this.paperCategory == 2
              ? "综合考试"
              : "",
        });
        return acc;
      }, []);
      this.paperListTotal = arr;
    },
    // 删除考试列表数据
    delPaperData(row) {
      this.$confirm(`是否删除，删除将无法恢复`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      })
        .then(() => {
          this.paperList = this.paperList.filter((item) => item.id !== row.id);
          this.paperCategory = "";
          // 点击删除后，考试列表去除勾选
          this.$nextTick(() => {
            this.paperTableData.forEach((val) => {
              if (row.id == val.id) {
                this.$refs.multipleTable.toggleRowSelection(val, false);
              }
            });
          });
          this.ruleForm.paperData = this.paperList
            .map((item) => item.id)
            .join("#");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 打开试卷会话框回调方法
    editPaperList() {
      // 如果flag=0是第一次打开会话框，那么就将回显的数据回显到会话框列表中
      if (this.dialogFlag == 0) {
        this.$nextTick(() => {
          this.paperTableData.forEach((val) => {
            if (this.editPaperId == val.id) {
              this.$refs.multipleTable.toggleRowSelection(val, true);
            }
          });
        });
        // 设置为1后，每次打开会话框就不会添加回显的数据到列表中，防止列表变成两条数据
        this.dialogFlag = 1;
      } else {
        return false;
      }
      // }
    },
    // 显示选择考场数据
    showExamRoom() {
      showExaminatinRoom().then((res) => {
        this.examRoomList = res.data;
      });
    },
    // 显示学生列表全部标识
    showStudentList() {
      examStudentList().then((res) => {
        if (res.code === 200) {
          this.studentCategoryList = res.data;
          let obj = {
            id: "全部",
            name: "全部",
          };
          this.studentCategoryList.unshift(obj);
        }
      });
    },
    // 显示评委列表
    showJudgeListData() {
      showExamJudgeList().then((res) => {
        if (res.code === 200) {
          this.judgeTable = res.data;
        }
      });
    },
    // 显示考试试卷下拉框数据
    showPaperListData() {
      showPaperList().then((res) => {
        if (res.code === 200) {
          this.paperTableData = res.data;
          if (this.$route.query.id) {
            this.editExamDataList();
          }
        }
      });
    },
    // 根据学生下拉框显示学生列表
    selectStudentList() {
      if (
        this.ruleForm.examRoom &&
        this.studentSelect &&
        this.ruleForm.startTime &&
        this.ruleForm.endTime
      ) {
        let params = {
          examination_room_id: this.ruleForm.examRoom,
          start_time: JSON.stringify(
            new Date(this.ruleForm.startTime).getTime()
          ).substring(0, 10),
          end_time: JSON.stringify(
            new Date(this.ruleForm.endTime).getTime()
          ).substring(0, 10),
        };
        if (this.studentSelect != "全部") {
          params.category_id = this.studentSelect;
        }
        showExamStudentList(params).then((res) => {
          if (res.code == 200) {
            this.studentTable = res.data;
          }
        });
      }
    },
    // 显示学生数据会话框
    changeStudent() {
      this.studentTitle = "选择学生";
      this.studentDialogVisible = true;
    },
    // 显示试卷数据会话框
    changePapers() {
      this.paperTitle = "选择试卷";
      this.paperDialogVisible = true;
    },
    // 显示评委数据会话框
    changeJudge() {
      this.judgeTitle = "选择评委";
      this.judgeDialogVisible = true;
    },
    // 点击确定添加数据到学生列表中
    studentConfirmForm() {
      let arr = [];
      let newArray = [];
      this.studentList.forEach((item) => {
        newArray.push(item.id);
      });
      if (this.studentChecked.length < this.studentList.length) {
        newArray.forEach((item) => {
          if (!this.studentChecked.includes(item)) {
            this.studentList = this.studentList.filter(
              (subItem) => subItem.id !== item
            );
          }
        });
        this.studentDialogVisible = false;
        return;
      }
      this.studentChecked.forEach((item) => {
        if (!newArray.includes(item)) {
          arr.push(item);
        }
      });
      let index = "";
      this.studentTable.forEach((item) => {
        arr.forEach((subItem) => {
          if (item.user_id == subItem) {
            let len = this.studentList.length;
            index = len == 0 ? 0 : this.studentList[len - 1].sid;
            let obj = {
              sid: index + 1,
              name: item.nickname,
              id: item.user_id,
            };
            this.studentList.push(obj);
          }
        });
      });
      this.ruleForm.studentData = this.studentList
        .map((item) => item.id)
        .join("#");
      this.studentDialogVisible = false;
    },
    // 点击确定添加数据到试卷列表中
    paperconfirmForm() {
      if (this.paperListTotal.length >= 2) {
        this.$message({
          type: "error",
          message: "只能选择一套试卷",
        });
        return false;
      } else {
        this.paperDialogVisible = false;
        this.paperList = this.paperListTotal;
        this.ruleForm.paperData = this.paperList
          .map((item) => item.id)
          .join("#");
        // 如果没有选择试卷分类就默认获取全部列表中的某一张试卷的考试等级
        if (this.paperValue == "全部") {
          this.paperAllvalue = this.paperList
            .map((item) => item.grade)
            .join("");
        } else {
          return false;
        }
      }
    },
    // 点击确定添加数据到评委列表中
    judgeConfirmForm() {
      let index = 1;
      let arr = this.judgeTable.reduce((acc, cur) => {
        if (this.judgeChecked.includes(cur.id)) {
          acc.push({
            id: cur.id,
            name: cur.nickname,
            jid: index++,
          });
        }
        return acc;
      }, []);
      this.judgeList = arr;
      this.ruleForm.judgeData = this.judgeList.map((item) => item.id).join("#");
      this.judgeDialogVisible = false;
    },

    // 删除学生列表某一项
    deleteStudentItem(row) {
      this.$confirm(`是否删除，删除将无法恢复`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      })
        .then(() => {
          this.studentList = this.studentList.filter(
            (item) => item.id !== row.id
          );
          this.studentChecked = this.studentChecked.filter(
            (item) => item !== row.id
          );
          this.ruleForm.studentData = this.studentList
            .map((item) => item.id)
            .join("#");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 删除评委列表某一项
    deleteJudgeItem(row) {
      this.$confirm(`是否删除，删除将无法恢复`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      })
        .then(() => {
          this.judgeList = this.judgeList.filter((item) => item.id != row.id);
          this.judgeChecked = this.judgeChecked.filter(
            (item) => item !== row.id
          );
          this.ruleForm.judgeData = this.judgeList
            .map((item) => item.id)
            .join("#");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 创建考试
    confirmForm(formName) {
      if (this.paperCategory == 1 && this.judgeList.length == 0) {
        this.$message({
          type: "error",
          message: "请选择评委",
        });
        return false;
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证考试时间
            let start_time = Date.parse(new Date(this.ruleForm.startTime));
            let end_time = Date.parse(new Date(this.ruleForm.endTime));
            let current_time = Date.parse(new Date());
            try {
               // if (start_time > end_time) {
               //    throw new Error('考试开始时间必须早于考试结束时间');
               // }
               // if (start_time < current_time) {
               //    throw new Error('考试开始时间必须晚于当前时间');
               // }
            } catch (e) {
                  this.$message.warning(e.message);
                  return;
            }
            let params = {
              examination_room_id: this.ruleForm.examRoom,
              exam_type:
                this.paperValue == "全部"
                  ? this.paperAllvalue
                  : this.paperValue,
              exam_form: this.paperCategory,
              student: this.ruleForm.studentData,
              paper_id: this.ruleForm.paperData,
              judge:
                this.paperCategory == 0
                  ? ""
                  : this.paperCategory == 1
                  ? this.ruleForm.judgeData
                  : this.paperCategory == 2
                  ? this.ruleForm.judgeData
                  : "",
              start_time: JSON.stringify(
                new Date(this.ruleForm.startTime).getTime()
              ).substring(0, 10),
              end_time: JSON.stringify(
                new Date(this.ruleForm.endTime).getTime()
              ).substring(0, 10),
              exam_name: this.ruleForm.examName,
            };
            if (this.ruleForm.id) {
              params.id = this.ruleForm.id;
            }
            createExamData(params)
              .then((res) => {
                if (res.code === 200) {
                  this.$message({
                    type: "success",
                    message: res.msg,
                  });
                  this.$router.push({
                    path: "/examManage/create",
                  });
                }
              })
              .catch((err) => {
                console.log("err", err);
              });
          } else {
            return false;
          }
        });
      }
    },

    // 编辑考试
    editExamDataList() {
      let params = {
        id: this.$route.query.id,
      };
      editExamData(params).then((res) => {
        if (res.code === 200) {
          this.ruleForm.examName = res.data.examination_info.exam_name;
          this.ruleForm.startTime = res.data.examination_info.start_time;
          this.ruleForm.endTime = res.data.examination_info.end_time;
          this.ruleForm.examRoom =
            res.data.examination_info.examination_room_id;
          this.paperValue = res.data.examination_info.exam_type;
          this.paperCategory = res.data.examination_info.exam_form;
          this.editPaperId = res.data.examination_info.paper_id;
          // 遍历考试考卷到考试列表
          let pindex = 0;
          let array = this.paperTableData.reduce((acc, cur) => {
            if (cur.id == res.data.examination_info.paper_id) {
              acc.push({
                id: res.data.examination_info.paper_id,
                name: cur.name,
                levelname: cur.level_name,
                category:
                  this.paperCategory == 0
                    ? "理论考试"
                    : this.paperCategory == 1
                    ? "实操考试"
                    : this.paperCategory == 2
                    ? "综合考试"
                    : "",
                pid: (pindex += 1),
              });
            }
            return acc;
          }, []);
          this.paperList = array;
          this.ruleForm.paperData = this.paperList
            .map((item) => item.id)
            .join("#");

          // 将评委数据回显到评委列表
          this.judgeList = [];
          this.judgeChecked = [];
          let jindex = 0;
          res.data.judge.forEach((Jitem) => {
            if (Jitem.nickname == null) {
              return false;
            } else {
              this.judgeChecked.push(Jitem.judge_id);
              let obj = {
                id: Jitem.judge_id,
                name: Jitem.nickname,
                jid: (jindex += 1),
              };
              this.judgeList.push(obj);
            }
          });
          this.ruleForm.judgeData = this.judgeList
            .map((item) => item.id)
            .join("#");

          // 遍历数据到学生列表
          this.studentList = [];
          this.studentChecked = [];
          let sindex = 0;
          res.data.student.forEach((sitem) => {
            this.studentChecked.push(sitem.student_id);
            let obj = {
              id: sitem.student_id,
              name: sitem.nickname,
              sid: (sindex += 1),
            };
            this.studentList.push(obj);
            this.allStudentList.push(obj);
          });

          this.selectStudentList();
          this.ruleForm.studentData = this.studentList
            .map((item) => item.id)
            .join("#");
        }
      });
    },

    // 取消
    cancelExam() {
      this.$router.push({
        path: "/examManage/create",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.create-exam {
  height: 100%;
  overflow-y: auto;
  padding: 11px 19px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .exam-hr {
    margin-top: 26px;
    border: none;
    border-bottom: 2px solid rgba(241, 245, 255, 1);
  }
  .exam-main {
    margin-top: 30px;
    .exam-title {
      display: flex;
      align-items: center;

      .green-block {
        width: 5px;
        height: 20px;
        background: #2dc079;
        border-radius: 3px;
        margin-right: 10px;
      }

      span {
        line-height: 1;
        &:first-of-type {
          font-size: 18px;
        }
        &:last-of-type {
          font-size: 16px;
          color: #999;
        }
      }
    }

    .exam-setting {
      margin: 41px 0 44px 0;
    }
    .change-btn {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    .student-table {
      margin: 15px 0 0 25px;
      border: 1px solid rgba(220, 220, 220, 1);
      width: 400px;
      height: 400px;
      overflow-y: auto;
      .student-check {
        display: flex;
        align-items: center;
        margin: 20px;
      }
    }

    .exam-time {
      position: relative;
      .exam-minute {
        position: absolute;
        right: -10%;
      }
    }

    .paper-category {
      display: flex;
      margin-bottom: 20px;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
